<template>
  <!--pages/success/index.wxml-->
  <div style="padding: 0px 15px">
    <div class="result-success">
      <div class="result-success-icon">
        <img
          class="icon-box-img"
          :src="require('@/assets/icons/icon_success.png')"
          type="success"
          size="85"
        />
      </div>
      <div class="result-success-title">{{ title }}</div>
      <div class="result-success-subtitle">{{ subtitle }}</div>
    </div>

    <div class="customer-service">
      {{ $t("包裹到达泰国后将通知您支付转运费并配送上门。") }}
    </div>
    <div v-if="showCustomService" @click="copyWechatId">
      <!-- <image class="wechat-icon" mode="aspectFit" src="../../static/icons/xmf-service.jpg"/> -->
      <!-- <div class="wechat-id">加客户微信{{ wechatId }}, </div> -->
      <p style="color: #969696">
        {{ $t("如有问题请联系客服微信") }} {{ wechatId }}
        <span style="color: #569cd6">{{ $t("[复制]") }}</span>
      </p>
    </div>
    <div class="action-bar">
      <van-button @click="goback" type="primary" style="margin-right: 25px">
        {{ $t("继续预报运单") }}
      </van-button>
      <van-button type="default" @click="gotoCargoList">{{
        $t("前往查件")
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from "vant";
import { EventBus } from "@/utils/event-bus.js";

export default {
  components: {
    [Button.name]: Button,
    [Toast.name]: Toast,
  },
  data() {
    return {
      showCustomService: true,
      title: this.$t("下单成功"),
      subtitle: "",
      wechatId: "@549tcrhe",
    };
  },
  methods: {
    goback() {
      this.$router.replace({ name: "cargoRegister" });
    },
    gotoCargoList() {
      EventBus.$emit("orders", "");
      this.$router.push({ name: "Home" });
    },
    copyWechatId() {
      let oInput = document.createElement("input");
      oInput.value = this.wechatId;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
  },
};
</script>
<style lang="less" scoped>
/* pages/success/index.wxss */
page {
  background-color: #fff;
}

.result-success {
  padding: 15px 0;
}

.result-success-icon {
  margin-bottom: 18px;
  text-align: center;
}

.result-success-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.result-success-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.customer-service {
  color: #969696;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.action-bar {
  margin-top: 20px;
  text-align: center;
}
</style>